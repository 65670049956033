import useTranslation from 'next-translate/useTranslation'
import { useSession } from 'next-auth/react'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import {
  HideInput,
  HideOfferMutation,
  ShowOfferMutation,
  useHideOfferMutation,
  useShowOfferMutation,
} from 'generated/apolloComponents'
import Link from 'next/link'
import { EyeSlashIcon } from '@heroicons/react/24/outline'

type Variables = {
  input: HideInput
}

interface IProps {
  jobId: string
  setIsVisible: Dispatch<SetStateAction<boolean>>
  visible?: boolean
}

const ToggleVisibleOffer = ({ jobId, setIsVisible, visible }: IProps) => {
  const { t } = useTranslation('common')
  const { data: session } = useSession()
  const [expanded, setExpanded] = useState(false)

  const expand = () => {
    setExpanded(true)
  }

  const close = () => {
    setTimeout(() => {
      setExpanded(false)
    }, 200)
  }

  const variables: Variables = {
    input: {
      jobId: jobId,
    },
  }
  const [hideOffer] = useHideOfferMutation({
    onCompleted: (data: HideOfferMutation) => {
      data.hideOffer?.success && setIsVisible(false)
    },
  })

  const [showOffer] = useShowOfferMutation({
    onCompleted: (data: ShowOfferMutation) => {
      data.showOffer?.success && setIsVisible(true)
    },
  })

  const handleHideClick = async (e: any) => {
    e.preventDefault()
    if (session) hideOffer({ variables: variables })
  }

  const handleShowClick = async (e: any) => {
    e.preventDefault()
    if (session) showOffer({ variables: variables })
  }

  return visible ? (
    <div>
      {expanded && !session && (
        <div className='opaque absolute -bottom-2 right-7 rounded-md bg-white font-normal w-96 py-6 px-6 text-sm pb-16 z-20 mb-0.5'>
          <p className='text-lg mb-3'>
            <strong>{t('common:hide_offer')}</strong>
          </p>
          <p className='mb-3'>
            {t('common:to_hide_offer')}{' '}
            <a
              href='/auth/login'
              className='font-medium underline hover:no-underline'
            >
              {t('common:login')}
            </a>
          </p>
          <p>
            {t('common:dont_have_account')}{' '}
            <Link href='/auth/register' prefetch={false}>
              <a className='font-medium underline hover:no-underline'>
                {t('common:create_it')}
              </a>
            </Link>{' '}
            {t('common:stop_looking_job')}
          </p>
          <EyeSlashIcon
            width={20}
            height={20}
            className={visible ? 'text-white' : 'text-gray-dark inline-block'}
          />
        </div>
      )}
      <button
        type='button'
        tabIndex={0}
        onClick={handleHideClick}
        onFocus={expand}
        onBlur={close}
        className={`inline-block rounded-full p-4  transition-all duration-200 ${
          !visible ? 'bg-purple' : 'bg-gray-light'
        }`}
        title={t('common:hide_offer')}
        data-tooltip-content={t('common:hide_offer')}
        data-tooltip-id='tooltip'
      >
        <EyeSlashIcon
          width={20}
          height={20}
          className={`stroke-2 ${!visible ? 'text-white' : 'text-gray-dark'}`}
        />
      </button>
    </div>
  ) : (
    <div>
      <button
        type='button'
        onClick={handleShowClick}
        className='inline-block rounded-full p-4 transition-all duration-200 bg-gray-light'
      >
        <span className='mr-3'>{t('common:back')}</span>{' '}
      </button>
    </div>
  )
}

export default ToggleVisibleOffer
